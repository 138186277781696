.promo-community{
    &__market{
        padding-top: 185px;
        @include mobile{
            padding-top: 83px;
        }
      &_box{
          display: grid;
        align-items: center;
          grid-template-columns: minmax(auto,520px) auto;
          grid-gap: 64px;
          @include tablet{
              grid-gap: 36px;
          }
          @include mobile{
          grid-template-columns: 1fr;
          }
      }
        &_content{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            /* or 140% */
            color: rgba(255, 255, 255, 0.85);
          margin-top: 32px;
            @include mobile{
                font-size: 14px;
                line-height: 22px;
                margin-top: 14px;
            }

        }
        &_content-title{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.7);
            letter-spacing: 0.4em;
            text-transform: uppercase;
        }

        &_slider{
            overflow: hidden;
            box-sizing: border-box;
            &.owl-carousel{
                display: none;
                @include tablet{
                    display: block;
                }
            }
             &.grid{
                 display: grid;
                 justify-content:space-between;
                 grid-gap: 10px;
                 grid-template-columns: repeat(2,1fr);
                 .promo-community__market_slider-item{
                     margin-bottom: 32px;
                 }
                 @include tablet{
                     display: none;
                 }
             }

            @include tablet{
              .owl-item{
                  display: flex;
                  justify-content: center;
              }
            }

            .owl-dots{
                display: flex;
                gap: 12px;
                margin-top: 16px;
                justify-content: center;
                .owl-dot{
                    opacity: 0.5;
                    border: 1px solid #25CBFF;
                    width: 12px;
                    height: 12px;
                    border-radius: 500px;
                    &.active{
                        background: #25CBFF;
                        border: 1px solid #25CBFF;
                    }
                }
            }
        }
           &_slider-item{
               width: 321px;
               height: 336px;
               display: flex;
               box-sizing: border-box;
               flex-direction: column;
               padding: 185px 20px 20px 45px;
               @include mobile{
                   padding: 185px 20px 20px 23px;
               }
               &.item1{
                   background: url("../images/market1.svg") no-repeat;
               }
               &.item2{
                   background: url("../images/market2.svg") no-repeat;
               }
               &.item3{
                   background: url("../images/market3.svg") no-repeat;
               }
               &.item4{
                   background: url("../images/market4.svg") no-repeat;
               }
               @include mobile{

               }
           }
        &_slider-title{
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 150.02%;
            color:$colorBlue ;
            @include mobile{
                font-size: 20px;
                line-height: 24px;
            }


        }
        &_slider-sum{
            font-style: normal;

            font-weight: 500;
            font-size: 24px;
            line-height: 150.02%;
            justify-self: left;
            /* identical to box height, or 36px */
            color:$colorWhite;
            margin-top: auto;
        }
    }
}
