.promo-community{
    &__sponsors{
        background: $colorWhite;
        padding-top: 121px;
        padding-bottom: 121px;
        @include tablet{
            padding-top: 106px;
            padding-bottom: 92px;
        }
        &_wrapper{
            margin-top: 92px;
            @include tablet{
             margin-top: 64px;
            }
        }
        &_box{
      margin-bottom: 80px;
            display: flex;
            justify-content:  center;
            gap: 32px;
            flex-wrap: wrap;
            &:last-child{
                margin-bottom: 0;
            }
            &.gold{
                  .promo-community__sponsors_item{
                      box-shadow: 0 0 10px rgba(37, 203, 255, 0.7);
                  }
            }
            @include tablet{
                gap: 23px;
                margin-bottom: 46px;
            }
        }
        &_item{
            width: 230px;
            height: 101px;
            background:$colorWhite;
            border-radius: 9px;
            display: flex;
            box-sizing: border-box;
            padding: 15px;
            align-items: center;
            justify-content: center;
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
            @include tablet{
                width: 222px;
            }
        }
    }
}
