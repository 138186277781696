.promo-community {
    &__header {
        &_date {
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 150.02%;
            /* identical to box height, or 57px */
            text-align: center;
            text-transform: lowercase;
            @include pTop(200px, 350px, 959px, 1800px, 350px);
            color:$colorWhite;
            padding-top: 350px;
            @include mobile{
                font-style: normal;
                font-weight: 700;
                padding-top: 315px;
                font-size: 20px;
                line-height: 150.02%;
                /* identical to box height, or 30px */

                text-align: center;
                text-transform: lowercase;
            }
        }

        &_logo {
            @include tablet{
                width: 750px;
            }
        }

        &_description {
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 150.02%;
            /* identical to box height, or 57px */
          text-transform: none;
            text-align: center;
            color:$colorWhite ;
            margin-top: -50px;
            @include tablet{
                margin-top: -30px;
            }
            @include mobile{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 150.02%;
                /* identical to box height, or 30px */
                margin-top: 0;
                text-align: center;

            }


        }

        &_description-bottom {
            font-style: normal;
            font-weight: 300;
            font-size: 38px;
            line-height: 150.02%;
            /* or 57px */
            text-align: center;
            color: #A7A7A7;
           margin-top: 211px;
            @include mobile{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                margin-top:127px;
                line-height: 150.02%;
            }
        }
    }
}
