//font
$font-base: 'Jura', sans-serif;
;
// Base Color
$colorWhite: #fff;
$colorBlack: #000;
$colorBlue: #25CBFF;
$border-radius: 20px;

$fullScreen: 1300px;
$tablet: 1279px;
$mobile: 959px;
@mixin tablet {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin fullScreen {
    @media (max-width: #{$fullScreen}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile}) {
        @content;
    }
}


@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);

    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;

    $b: $b + $units;

    @return calc(#{$k} * 100vw + #{$b});
}

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    font-size: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback;
        }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}

@mixin fluidLineH($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    line-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            line-height: $fallback;
        }
        line-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        line-height: $f-max;
    }
}


@mixin absRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            right: $fallback;
        }
        right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        right: $f-max;
    }
}


@mixin gridGap($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    grid-gap: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            grid-gap: $fallback;
        }
        grid-gap: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        grid-gap: $f-max;
    }
}


@mixin absBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            bottom: $fallback;
        }
        bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        bottom: $f-max;
    }
}


@mixin minHeight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    min-height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            min-height: $fallback;
        }
        min-height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        min-height: $f-max;
    }
}


@mixin pBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-bottom: $fallback;
        }
        padding-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-bottom: $f-max;
    }
}


@mixin pRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-right: $fallback;
        }
        padding-right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-right: $f-max;
    }
}


@mixin mBottom($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-bottom: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-bottom: $fallback;
        }
        margin-bottom: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-bottom: $f-max;
    }
}

@mixin mLeft($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-left: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-left: $fallback;
        }
        margin-left: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-left: $f-max;
    }
}


@mixin mRight($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-right: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-right: $fallback;
        }
        margin-right: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-right: $f-max;
    }
}


@mixin mTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    margin-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            margin-top: $fallback;
        }
        margin-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        margin-top: $f-max;
    }
}


@mixin pTop($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    padding-top: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            padding-top: $fallback;
        }
        padding-top: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        padding-top: $f-max;
    }
}

@mixin Width($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    width: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            width: $fallback;
        }
        width: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        width: $f-max;
    }
}


@mixin Height($f-min, $f-max, $w-min, $w-max, $fallback: false) {

    height: $f-min;

    @media (min-width: $w-min) {
        @if ($fallback) {
            height: $fallback;
        }
        height: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        height: $f-max;
    }
}

body {
    font-family: $font-base;
    background: $colorBlack;
}
body,html{
    height: 100%;
}

.overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    top: 0;
    z-index: 10;
}
.preloader{
    width: 50px;
    height: 50px;
    border: 6px solid transparent;
    border-bottom: 6px solid $colorBlue ;
    border-left: 6px solid $colorBlue ;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;

    z-index: 11;
    transform: translate(-50%, -50%);
    animation: animate 1s infinite linear;
}
/* Анимируем наш прелоадер */
@keyframes animate{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}



.promo-community {

    &__screen-top {
        position: relative;
        background: $colorBlack url("../images/screen-top-desctop.jpg") no-repeat center top;
        min-height: 2902px;

        @include tablet {
            background: $colorBlack url("../images/screen-top-tablet.jpg") no-repeat center top;
            min-height: 2802px;
        }

        @include mobile {
            background: $colorBlack url("../images/screen-top-mobile.jpg") no-repeat center top;
            min-height: 2931px;
        }

    }

    &__wrapper {
        max-width: 1600px;
        width: 100%;
        box-shadow: 0px 0 50px $colorBlue;
        margin: 0 auto;
        position: relative;
        @include tablet {
            max-width: 1024px;
        }
        @include mobile {
            max-width: 375px;
        }
    }


    &__container {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        @include fullScreen {
            box-sizing: border-box;
            padding-right: 20px;
            padding-left: 20px;
        }

    }

    &__screen-bottom {
        background: $colorBlack url("../images/screen-bottom-desctop.jpg") no-repeat top center;
      /*  min-height: 5229px;*/
        padding-bottom: 140px;
        @include tablet {
            background: $colorBlack url("../images/screen-bottom-tablet.jpg") no-repeat top center;
      /*      min-height: 4518px;*/
        }
        @include mobile {
            background: $colorBlack url("../images/screen-bottom-mobile.jpg") no-repeat center top;
       /*     min-height: 4480px;*/
        }

    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 52px;
        /* identical to box height, or 137% */

        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $colorBlue;
        margin-bottom: 22px;
        @include mobile{
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 14px;
        }

        &.gray {
            color: #212121;

        }

        &.align-left{
            text-align: left;
        }



    }

    &__title-sm {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        line-height: 32px;
        color: $colorWhite;
        &.align-left{
            text-align: left;
        }
        @include mobile{
            font-size: 14px;
            line-height: 22px;
        }
    }

    &__description {
        margin-top: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        font-size: 16px;
        line-height: 150.02%;

        &.white {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: rgba(255, 255, 255, 1);
            margin-top: 16px;
        }
    }

    &__box-banners {
        padding-top: 121px;
        background: $colorWhite;
        padding-bottom: 121px;
        @include mobile{
             padding-top: 64px;
        }
        &_title {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */
            letter-spacing: 0.4em;
            text-transform: uppercase;
            color: rgba(255, 255, 255,  0.7);
            margin-bottom: 16px;
            @include mobile{
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 12px;
            }
        }

        &_name {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color:$colorWhite ;
            @include mobile{
                font-size: 16px;
                line-height: 24px;
            }
        }

        &_text {
         margin-top: 67px;
            @include mobile{
                margin-top: 23px;
            }
        }
        &_text-item {
            color: rgba(255, 255, 255,  0.7);
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 35px;
            position: relative;
            padding-left: 20px;
            @include mobile{
                font-size: 14px;
                line-height: 22px;          margin-bottom: 24px;
            }
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                content: "";
                width: 11px;
                height: 11px;
                position: absolute;
                border-radius: 500px;
                background: #999999;
                top: 9px;
                left: -8px;
            }
            span{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                  display: inline-block;
                line-height: 18px;
            }
        }

        &_qoute {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            position: relative;
            @include mobile{
                font-size: 14px;
                line-height: 22px;
            }
            &_crypto {
                  color:$colorWhite ;
                max-width: 503px;
                margin-left: auto;
                margin-right: 90px;
                position: relative; z-index: 0;
                @include tablet{
                    margin-left: 100px;
                    margin-right: 90px;
                    margin-top: 130px;
                }
                @include mobile{
                    margin-left: 40px;
                    margin-right: 20px;
                    margin-top: 0;
                    color: #151515;
                }
                &:before{
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    top: -16px;z-index: -1;
                    left: -20px;
                    background: url("../images/cr-qoute.svg") no-repeat;
                    @include mobile{
                        background: url("../images/lacky-quote.svg") no-repeat;
                        width: 26px;
                        height: 26px;       left: -10px;
                        background-size: contain;
                    }
                }
            }

            &_lacky-spin {
                color: #151515;
                max-width: 446px;
                margin-left: auto;
                margin-right: 90px;
                position: relative; z-index: 0;
                @include tablet{
                    margin-left: 80px;
                    margin-right: 50px;
                    margin-top: 30px;
                }
                @include mobile{
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-top: 0;
                }
                &:before{
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    top: -14px;z-index: -1;
                    left: -20px;
                    background: url("../images/lacky-quote.svg") no-repeat;
                    @include mobile{
                        width: 26px;
                        height: 26px;       left: -10px;
                        background-size: contain;
                    }
                }
            }

        }
    }


}

