.promo-community {
    &__advantages {
        min-height: 911px;
        position: relative;
        @include tablet{
            min-height:  auto;
        }

        &_title-box {
            position: absolute;
            top: 540px;
            left: 0;
            @include tablet{
              position: relative;
                top: inherit;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-top: 215px;
                .promo-community{
                    &__title-sm {
                        &.align-left{
                            text-align: center;
                        }
                    }
                }
            }

            @include mobile{
                padding-top: 114px;
            }
        }
        &_item-box{
         display: grid;
            grid-template-columns: repeat(2,1fr);
           padding-top: 220px;
            grid-gap: 36px;
            @include mobile{
                padding-top: 73px;
                grid-template-columns: 1fr;
                grid-gap: 16px;
            }
        }

        &_item {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: rgba(255, 255, 255, 0.6);
            gap: 24px;
            position: absolute;
            max-width: 433px;
            box-sizing: border-box;
            padding-left: 130px;
            min-height: 104px;
            display: flex;
            align-items: center;


            &.item1 {
                background: url("../images/advantages1.svg") no-repeat left center;
                right: 150px;
                top: 278px;
            }

            &.item2 {
                background: url("../images/advantages2.svg") no-repeat left center;
                right: 75px;
                top: 478px;
            }

            &.item3 {
                background: url("../images/advantages3.svg") no-repeat left center;
                right: 70px;
                top: 676px;
            }

            &.item4 {
                max-width: 512px;
                background: url("../images/advantages4.svg") no-repeat left center;
                right: 50px;
                top: 878px;

            }
            @include tablet{
                position: relative;
                top: inherit !important;
                left: inherit !important;
                right: inherit !important;
                @include mobile{
                    font-size: 14px;
                    line-height: 22px;
                    padding-left: 120px;
                }
            }
        }
    }
}
