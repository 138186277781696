.promo-community {

    &__footer {
        background: $colorBlack url("../images/footer.jpg") no-repeat center -230px;
        min-height: 547px;
        position: relative;
        @include tablet {
            background: $colorBlack url("../images/footer-tablet.jpg") no-repeat top -230px;
            min-height: 547px;
        }

        @include mobile {
            background: $colorBlack url("../images/footer-mobile.jpg") no-repeat top center;
            min-height: 576px;
        }

        &_box {
            padding-top: 117px;
            @include tablet {
                padding-top: 137px;
            }
        }

        &_search-box {
            display: flex;
            justify-content: center;
            margin-top: 32px;
        }

        &_search {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;


            color: $colorWhite;
            padding: 9px 16px 11px 16px;
            border: 1px solid $colorWhite;
            border-radius: 5px;

            text-decoration: none;
        }

        &_social-box {
            margin-top: 92px;
            display: flex;
            justify-content: center;
            gap: 56px;
            @include mobile{
                gap: 32px;
            }

        }

        &_social-item {
            background-color: rgba(255, 255, 255, 0.07);
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 500px;
            width: 53px;
            text-decoration: none;
            height: 53px;
            &.mobile{
                width: 30px;
                height: 30px;
            }

            &_tube {
                background-image: url("../images/youtube.svg");
              &.mobile{
                  background-image: url("../images/tube-m.svg");
              }
            }

            &_vk {
                background-image: url("../images/vk.svg");
                &.mobile{
                    background-image: url("../images/vk-m.svg");
                }
            }

            &_tg {
                background-image: url("../images/telegram-plane.svg");

                &.mobile{
                    background-image: url("../images/tg-m.svg");
                }


            }
            @include mobile{

            }
        }

    }
}
