.promo-community{
    &__crypto{
        background:url("../images/cr-bg-desctop.jpg") no-repeat;
        width: 100%;
        height: 701px;
        margin-top: 51px;
        display: grid;
        box-sizing: border-box;
        padding-left: 67px;
        padding-top: 67px;
        grid-template-columns:minmax(auto,484px) auto;
        @include tablet{
            background:url("../images/cr-bg-tablet.jpg") no-repeat;
            height: 701px;
            grid-template-columns:minmax(auto,459px) auto;
        }

        @include mobile{
            grid-template-columns: 1fr;
            background:url("../images/cr-bg-mobile.jpg") no-repeat;
            height: 803px;
            width: 343px;
            margin-top: 32px;
            padding-left: 32px;
            padding-right: 32px;
            padding-top: 47px;
        }

        &_img{
            margin-top: -170px;
            margin-left: -51px;
            @include tablet{
                margin-left: -10px;
            }
            @include mobile{
                margin-top: -20px;
                margin-left: 81px;
                width: 231px;
                height: 198px;
            }
        }
    }
}
