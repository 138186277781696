.promo-community {
    &__draw-places {
        padding-top: 406px;
        position: relative;

        @include mobile {
            padding-top: 114px;
        }

        &_box-places {
            display: grid;

            &.top {
                grid-template-columns: 1fr;
                margin-bottom: 31px;
                margin-top: 92px;
                @include mobile {
                    margin-top: 42px;
                }
            }

            &.middle {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                margin-bottom: 31px;
                @include mobile {
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                }
            }

            &.last {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 24px;
                @include mobile {
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                }
            }
        }

        &_places-text {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150.02%;
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            margin-top: 72px;
            @include tablet {
                margin-top: 41px;
            }
            @include mobile {
                font-size: 12px;
                line-height: 20px;
            }
        }


        &_places-item {
            color: $colorWhite;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 50px;
            padding-bottom: 43px;

            position: relative;
            z-index: 2;

            &.one {
                background: url("../images/prize1-desctop.svg") no-repeat;
                box-sizing: border-box;
                width: 100%;
                height: 331px;
                padding-top: 0;
                padding-bottom: 0;

                @include tablet {
                    background: url("../images/prize1-tablet.svg") no-repeat;
                }

                @include mobile {
                    background: url("../images/prize1-mobile.svg") no-repeat;
                    height: 214px;
                }

                .promo-community {
                    &__draw-places {
                        &_places-sum {
                            font-weight: 600;
                            font-size: 64px;
                            line-height: 150.02%;
                            margin-bottom: 0;
                            @include mobile {
                                font-size: 38px;
                                line-height: 150.02%;
                            }
                        }

                        &_places-title {
                            margin-bottom: 0px;
                        }

                        &_places-description {
                            margin-top: -5px;
                        }
                    }
                }
            }

            &.other {
                background: rgba(37, 203, 255, 0.1);
                border-radius: $border-radius;
            }
        }

        &_places-num {
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 150.02%;
            /* identical to box height, or 57px */
            text-align: center;
            margin-bottom: 26px;
            @include mobile {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 8px;
            }
        }

        &_places-sum {
            font-style: normal;
            font-weight: 600;
            font-size: 42.6667px;
            line-height: 150.02%;
            /* identical to box height, or 64px */
            text-align: center;
            color: $colorBlue;
            text-shadow: 0 0 40px $colorBlue;
            margin-bottom: 3px;
            @include tablet {
                font-size: 38px;
            }
            @include mobile {
                font-size: 32px;
                line-height: 150.02%;
            }
        }

        &_places-title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150.02%;
            /* identical to box height, or 30px */
            text-align: center;
            margin-bottom: 3px;
            @include mobile {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 3px;
            }
        }

        &_places-description {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 150.02%;
            /* identical to ox height, or 30px */
            text-align: center;
            color: rgba(255, 255, 255, 0.6);
            margin-top: auto;
            @include mobile {
                font-size: 14px;
                line-height: 22px;
            }
        }

        &_parallax {
            z-index: 3;
            position: absolute;
            width: 70px;
            height: 70px;

            &.item1 {
                left: 106.67px;
                top: 768.82px;
                @include mobile {

                    left: 10px;
                }
            }

            &.item2 {
                left: 94.67px;
                bottom: 30px;
                @include mobile {
                    bottom: 230px;
                    left: 10px;
                }
            }

            &.item3 {
                right: 106px;
                bottom: 460px;
                @include mobile {

                    right: 10px;
                }

            }

            &.item4 {
                right: 673px;
                bottom: 500px;

            }

            &.item5 {
                left: 10%;
                bottom: 35%;

                @include mobile {
                    left: inherit;
                    right: 10px;
                }
            }

            &.item6 {
                right: 150px;
                bottom: 60px;

            }

            &.item7 {
                right: 40%;
                bottom: 60px;
                @include mobile {

                    right: 10px;
                }
            }

            &.item8 {
                right: 159px;
                bottom: 10%;
                z-index: 1;

            }

            &.item9 {
                left: 36.81%;
            bottom: 45%;

            }


            &.item10 {
                left: 47%;
                bottom: 20%;

            }

            &.item11 {
                right: 10%;
                bottom: 35%;
                z-index: 1;

                @include mobile {

                    right: 10px;
                }
            }
        }
    }
}
