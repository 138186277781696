.promo-community {
    &__bonus {
        margin-top: 230px;
        @include tablet{
            margin-top: 192px;
        }
        @include mobile{
            margin-top: 114px;
        }

        &_box {
            margin-top: 97px;
            grid-template-columns:  minmax(735px, auto) auto;
            display: grid;
            grid-gap: 26px;
            @include tablet{
                grid-gap: 21px;
                grid-template-columns:  1fr 1fr;
            }
            @include mobile{
                margin-top: 64px;
                grid-template-columns: 1fr;
            }
        }

        &_item {
            background: rgba(37, 203, 255, 0.03);
            padding: 31px 35px 33px 35px;
            border-radius: $border-radius;
            @include mobile{
                padding: 15px;
            }

            &.right{
                padding: 32px 69px 30px 46px;
                @include mobile{
                    padding: 25px;
                }
            }
        }

        &_points {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 27px;
            grid-gap: 14px;
            @include mobile{
           grid-template-columns: 1fr;
            }
        }

        &_points-item {
            background: rgba(245, 247, 249, 0.1);
            padding: 12px 20px 9px 12px;
            border-radius: 7px;
        }

        &_points-title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color:$colorWhite ;
        }
        &_points-description{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.5);
        }
        &_forecast-title{
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color:$colorWhite ;
            margin-bottom: 25px;
            @include mobile{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
        }

        &_forecast-navigation{
       margin-bottom: 16px;
            position: relative;

        }
        &_forecast-navigation-title{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;     padding-left: 20px;
            position: relative;
            color: rgba(255, 255, 255, 0.5);

          @include mobile{
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
          }



            &:before{
                content: "";
                width: 11px;
                height: 11px;
                position: absolute;
                top: 6px;
                left: -8px;
                margin-right: 20px;
                border-radius: 500px;
                border: 1px solid rgba(255, 255, 255, 0.5);;
            }
        }
        &_forecast-navigation-points{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
          padding-left: 20px;
           line-height: 24px;
            color:$colorWhite ;
            @include mobile{
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
            }
        }

        &_quote{
                background: url("../images/quote.svg") no-repeat;
            width: 750px;
            height: 152px;
            margin-top: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color:$colorWhite ;
            margin-left: auto;
            margin-right: auto;
            flex-direction: column;
            @include mobile{
                width: 333px;
                height: 126px;     margin-top: 26px;
                background: url("../images/quote-m.svg") no-repeat;

            }
        }

        &_quote-title{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 150.02%;
            /* identical to box height, or 30px */
            text-align: center;
            @include mobile{
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
            }
        }

        &_quote-description{
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 150.02%;
            /* or 30px */
            text-align: center;
            @include mobile{
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }


}
