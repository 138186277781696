.promo-community{
    &__progress-box {
        display: grid;
        grid-template-columns: 2fr  1fr 1fr;
        grid-gap: 4px;




        &_progress-bar {
            display: block;
            position: relative;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 8px;

            span {
                position: absolute;
                display: inline-block;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color:$colorWhite ;
                z-index: 3;
                vertical-align: middle;
                align-items: center;
                justify-content: center;
                @include mobile{
                    white-space: nowrap;
                }

            }
        }

        &_sum-conversions {
            display: flex;
            justify-content: space-between;

            &.center{
               justify-content: center;
            }
            &_value {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #C5C7D0;
                min-height: 23px;
                // background: aqua;
                position: relative;
                &.gray{
                    color: #4D5163;
                }
                &.mrg-right{
                    margin-right: -15px;
                    @include mobile{
                        margin-right: -5px;
                    }
                }
                &.white{
                    color: $colorWhite;
                }

                @include mobile{
                    margin-left: -20px;
                }
            }
        }

        &_progress {
            &[value]::-webkit-progress-bar {
                background-color: rgba(37, 203, 255, 0.4);
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;

            }

            &[value] {
                background-color: rgba(37, 203, 255, 0.4);
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
                appearance: none;
                border: none;
                width: 100%;
                height: 16px;
                color: royalblue;
                position: relative;
            }

            &::-webkit-progress-value {
                background-color:  $colorBlue;
                border-radius: 20px;
            }

            &::-moz-progress-bar {
                background-color:  $colorBlue;
                border-radius: 20px;
            }


            &.border-right-nonactive{
                &[value]::-webkit-progress-bar {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    border-bottom-right-radius: 20px;
                    border-top-right-radius: 20px;

                }

                &[value] {

                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    border-bottom-right-radius: 20px;
                    border-top-right-radius: 20px;

                }
            }

           &.border-none-active{
               &::-webkit-progress-value {
                   background-color:  $colorBlue;
                   border-radius: 0;
               }

               &::-moz-progress-bar {
                   background-color:  $colorBlue;
                   border-radius: 0;
               }
           }

            &.border-left-active{


                &::-webkit-progress-value {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }

                &::-moz-progress-bar {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }


            }


            &.border-right-active{


                &::-webkit-progress-value {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }

                &::-moz-progress-bar {
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }


            }



        }

        &_target {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__target{
        margin-top: 274px;
        @include tablet{
            margin-top: 176px;
        }
       &_box{
           display: grid;
           grid-template-columns: auto minmax(735px,auto);
           margin-top: 94px;
           grid-gap: 25px;
           @include tablet{
               grid-template-columns: 1fr 1fr;
           }

           @include mobile{
               grid-template-columns: 1fr;
               margin-top: 64px;
           }
       }
        &_num-box{ display: grid;
            grid-template-columns: 144px auto;
            grid-gap: 15px;
            margin-bottom: 50px;
            @include mobile{
                grid-template-columns: 105px auto;
            }
            &:last-child{
                margin-bottom: 0;
            }
        align-items: center}
        &_num-text{
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            /* or 140% */

            color: #FFFFFF;
            opacity: 0.6;
            @include mobile{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }
        &_num{
            background: url("../images/target-num1.svg") no-repeat;
            width: 144px;
            height: 141px;
            @include mobile{
                width: 105px;
                height: 104px;
                background-size: contain;
            }
            &.two{
                background: url("../images/target-num2.svg") no-repeat;
                @include mobile{
                    width: 105px;
                    height: 104px;
                    background-size: contain;
                }
            }
        }

        &_progress-name-user{
            display: flex;        margin-bottom: 17px;
            align-items: center;
            justify-content: space-between;
            @include mobile{

            }
        }

        &_progress-name{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            position: relative;
            color: rgba(255, 255, 255, 0.5);
            @include mobile{
                font-size: 14px;
                line-height: 22px;
            }
            &:before{
                border: 2px solid rgba(255, 255, 255, 0.5);
                width: 30px;
                content: "";
                display: inline-block;
                vertical-align: middle;
                height: 30px;
                border-radius: 500px;
                margin-right: 12px;
                @include mobile{
                    width: 26px;
                    height: 26px;
                }
            }
        }
        &_progress-user{
            background: rgba(245, 247, 249, 0.5);
            border-radius: $border-radius * 5;
            padding: 5px 12px 5px 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
            color:$colorBlack;
            &:after{
                background: url("../images/user.svg") no-repeat;
                width: 14px;
                content: "";
                display: inline-block;
                vertical-align: middle;
                height: 16px;

                 margin-left: 6px;
            }
        }


        &_progress-container{
            background: rgba(37, 203, 255, 0.03);
            border-radius:$border-radius;
            padding: 31px 34px 19px 34px;
            margin-bottom: 15px;
            @include tablet{
                padding: 32px;
            }
            @include mobile{
                padding: 15px;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
