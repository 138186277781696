.promo-community{
    &__lucky-spin{
      background:url("../images/lacky-bg-desctop.jpg") no-repeat;
        width: 100%;
        height: 615px;
        margin-top: 51px;
        margin-bottom: 143px;
        display: grid;
        box-sizing: border-box;
        padding-left: 67px;
        padding-top: 67px;
        grid-template-columns:minmax(auto,484px) auto;
        @include tablet{
            grid-template-columns: minmax(auto,363px) auto;
            background:url("../images/lacky-bg-tablet.jpg") no-repeat;
            height: 615px;

        }
        @include mobile{
            grid-template-columns: 1fr;
            background:url("../images/lacky-bg-mobile.jpg") no-repeat;
            height: 675px;
            width: 343px;
            margin-top: 32px;
            padding-left: 32px;
            padding-top: 47px;
            padding-right: 32px;
            margin-bottom: 64px;
        }

        &_img{

            margin-top: -181px;
            margin-left: 104px;
            @include tablet{
                width: 493px;
                height: 501px;
                margin-top: -131px;
                margin-left: 29px;
            }
            @include mobile{
                width: 200px;
                height: 200px;
                margin-top: 0;
                margin-left: 90px;
            }
        }
    }
}
