.promo-community{
      &__navigation-scroll{
          position: fixed;
          top: 0;
          z-index: 5;
          width: 1600px;
          max-width: 100%;
          @include tablet{
              width: 1024px;
          }

          @include mobile{
              width: 375px;
          }
      }
    &__navigation{
        height: 80px;
        box-sizing: border-box;
    width: 100%;
        position: relative;
        @include mobile{
            height: 54px;
        }
        backdrop-filter: blur(20px);
        background: rgba(1, 9, 12, 0.8);
        &_box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            @include mobile{
                height: 54px;
                justify-content: left;
            }
        }
        &_item{
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            /* identical to box height, or 140% */
      cursor: pointer;
            text-align: center;
            letter-spacing: 0.2em;

            color: $colorWhite;
            cursor: pointer;
            @include mobile{
       text-align: left;
                font-size:16px;
                line-height: 24px;
                margin-bottom: 32px;
            }

        }
        &_button{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
           text-decoration: none;
            display: flex;
            align-items: center;
            text-align: center;
            padding: 5px 16px 7px 16px;
            color: $colorBlue;
            border: 1px solid #25CBFF;
            border-radius: 3px;
            @include mobile{
          margin-left: auto;
            }
        }
        &_logo{}
        &_menu{}
        &_click-menu{}

        &_mobile-menu-burger{
            display: none;
            width: 15px;
            height: 13px;
            cursor: pointer;
            background: url("../images/menu-burger.svg") no-repeat;
            @include mobile{
                display: block;
            }


        }

        &_mobile-menu-close{
            display: none;
            width: 12px;
            position: absolute;
            height: 12px;
            left: 18px;
            top: 18px;
            cursor: pointer;
            background: url("../images/close-mobile-menu.svg") no-repeat;
            @include mobile{
                display: block;
            }


        }




        &_mobile-logo{
            display: none;
            width: 60px;
            margin-left: 18px;
            height: 13px;
            background: url("../images/logo-leads.svg") no-repeat;
            @include mobile{
                display: block;
            }
        }

        &_menu-box{
            display: flex;
            justify-content: space-between;
            width:80%;
            @include mobile{
                position: absolute;
                top: 0;
                left: 0;
                flex-direction: column;
              visibility: hidden;
                justify-content: normal;
                width: 291px;
                min-height:100vh;
                padding-top: 106px;
                box-sizing: border-box;
                background: #040B0D;
                padding-left: 18px;
                box-shadow: 10px 0 30px rgba(0, 0, 0, 0.5);
            }
            .promo-community__footer_social-box{
                margin-top: 12px;
                justify-content: left;
            }
        }

        &_mobile-block{
            margin-top: auto;
            display: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            padding-bottom: 31px;
            color: rgba(255, 255, 255, 0.6);
            /* or 157% */
            @include mobile{
                display: block;
            }
        }
    }
}
